var meetings = [
    // 2024-2025 Competition Meetings
    {
        'date': new Date(2024, 9, 6),
        'name': 'Practice Meeting',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span><strong>Parents are invited to first 30-60 minutes.</strong> Information on upcoming year (including ARML, HMMT, TST, POTDs, and more). </span>)
    },
    {
        'date': new Date(2024, 10, 10),
        'name': 'Practice Meeting',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span>Competition practice. <strong>Please bring a device.</strong></span>)
    },
    {
        'date': new Date(2025, 1, 22),
        'name': 'Practice Meeting',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span>Competition practice. <strong>Please bring a device.</strong></span>)
    },
    {
        'date': new Date(2025, 3, 6),
        'name': 'Practice Meeting',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span>Competition practice. <strong>Please bring a device.</strong></span>)
    },
    {
        'date': new Date(2025, 4, 4),
        'name': 'Practice Meeting',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span>Competition practice. <strong>Please bring a device.</strong></span>)
    },
    // 2024-2025 TSTs
    {
        'date': new Date(2024, 11, 9), 
        'name': 'HMMT TST',
        'ShortLocation': 'WVA', 
        'LongLocation': 'Willamette Valley Academy',
        'Time': '2:00 PM - 4:00 PM',
        'Description': (<span>Team Selection Test for HMMT. <strong>Please bring a pencil or pen.</strong></span>)
    },

    // classes 
    // October - Group Theory
    {
        'date': new Date(2024, 9, 12),
        'name': 'Group Theory Class 1',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '1:00 PM - 2:30 PM',
        'Description': (<span>Introduction to the essense of group theory. We go over the definition of a group and the intuition behind what makes groups important.</span>)
    },
    {
        'date': new Date(2024, 9, 19),
        'name': 'Group Theory Class 2',
        'ShortLocation': 'WVA',
        'LongLocation': 'Willamette Valley Academy',
        'Time': '1:00 PM - 2:30 PM',
        'Description': (<span>Introduction to the essense of group theory. We continue talking about the importance of groups, going over some special types.</span>)
    },
    {
        'date': new Date(2024, 9, 26),
        'name': 'Group Theory Class 3',
        'ShortLocation': 'Online',
        'LongLocation': 'Discord Voice Call',
        'Time': '1:00 PM - 2:30 PM',
        'Description': (<span>Introduction to the essense of group theory. We move to the specific topic of group actions, which take the symmetries present and apply them to different sets.</span>)
    },
    {
        'date': new Date(2024, 10, 2),
        'name': 'Group Theory Class 4',
        'ShortLocation': 'Online',
        'LongLocation': 'Discord Voice Call',
        'Time': '1:00 PM - 2:30 PM',
        'Description': (<span>Introduction to the essense of group theory. As a culmination of everything from the past weeks, we go over a special and advanced theorem in Group Theory which has both beautiful math behind it as well as many practical applications.</span>)
    },

    // Competitions
    {
        'date': new Date(2025, 1, 15),
        'name': 'HMMT',
        'ShortLocation': 'Boston, MA',
        'LongLocation': 'MIT & Harvard Universities',
        'Time': 'Multi-day trip.',
        'Description': (<span>We will leave on the 13th or 14th (Thu/Fri) and return on the 16th (Sun).</span>)
    },
    {
        'date': new Date(2025, 4, 30),
        'name': 'ARML',
        'ShortLocation': 'Reno, NV',
        'LongLocation': 'University of Nevada, Reno, campus',
        'Time': 'Multi-day trip.',
        'Description': (<span>Groups will leave on the 29th (Thu) and 30th (Fri), and will return on the 31st (Sat).</span>)
    },
]

export default meetings;